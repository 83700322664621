import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Select, MenuItem, Button, Grid } from '@material-ui/core';
import HeaderAndLayout from '../layouts/HeaderAndLayout';
import FormDialog from './dialog';
import './resources.css';
import axios from "axios";
import BeatLoader from 'react-spinners/BeatLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Resources() {
    const [open, setOpen] = useState(false);
    const [rowUpdate, setRowUpdate] = useState(false)
    const [formValues, setFormValues] = useState({});
    const [tableData, setTableData] = useState([]);
    const [callingBackend, setCallingBackend] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [failMessage, setFailMessage] = useState(false);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const notify = () => successMessage ? toast.success('Action successful!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : failMessage ? toast.error('Action failed!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : null

    const loadData = async () => {
        try {
            setCallingBackend(true);
            const { data } = await axios.get("/resource/admin", axiosConfig);
            setTableData(data);
            setCallingBackend(false);
        } catch (error) {
            setFailMessage(true);
        }
    };

    useEffect(() => {
        loadData();
        if (successMessage || failMessage) {
            notify();
            setSuccessMessage(false);
            setFailMessage(false);
        }
    }, [successMessage, failMessage]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setRowUpdate(false);
        setFormValues({});
        setOpen(false);
    };

    const onChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setFormValues(prevState => ({
                    ...prevState,
                    image: reader.result
                }));
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleFormSubmit = async (e) => {
        try {
            if (rowUpdate) {
                await axios.put(`/resource/${formValues._id}`, formValues, axiosConfig);
            } else {
                await axios.post("/resource", formValues, axiosConfig);
            }
            setRowUpdate(false);
            setOpen(false);
            setFormValues({});
            setSuccessMessage(true);
            loadData();
            e.preventDefault();
        } catch (error) {
            setFailMessage(true);
        }
    };

    const columns = [
        { title: "Code", field: "code" },
        { title: "Name", field: "name" },
        { title: "Type", field: "type", render: rowData => rowData.type ? rowData.type.code : '' },
        { title: "Category", field: "category", render: rowData => rowData.category ? rowData.category.code : '' },
        {
            title: "Owner", field: "owner", render: rowData => rowData.owner && rowData.ownerApproval ?
                rowData.owner.email : rowData.owner && !rowData.ownerApproval ? "Needs Claim Approval" : ''
        },
        {
            title: 'Status',
            field: 'status',
            editComponent: props => (
                <Select
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                >
                    <MenuItem value={'pending'}>Pending</MenuItem>
                    <MenuItem value={'approved'}>Approved</MenuItem>
                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
            )
        }
    ]

    return (
        <div className="container" style={{ marginTop: "2rem" }}>
            <HeaderAndLayout activeItem={"resources"} />
            <Grid align="right" >
                <Button
                    style={{ backgroundColor: "#009E60", color: "#ffff", marginBottom: "1rem" }}
                    variant="contained"
                    onClick={handleClickOpen}>+ Create Resource</Button>
            </Grid>
            {callingBackend ? <BeatLoader /> :
                <MaterialTable columns={columns} data={tableData}
                    title="Resources"
                    options={{ search: false, filtering: false, paging: false, paginationType: "stepped", actionsColumnIndex: -1, addRowPosition: "first" }}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit Resource',
                            onClick: (event, rowData) => {
                                setFormValues(rowData);
                                setRowUpdate(true);
                                setOpen(true);
                            }
                        }
                    ]}
                />}
            <FormDialog open={open} handleClose={handleClose} data={formValues} onChange={onChange} handleImageChange={handleImageChange} handleFormSubmit={handleFormSubmit} axiosConfig={axiosConfig} rowUpdate={rowUpdate} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Resources