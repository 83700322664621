import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HeaderAndLayout from "../layouts/HeaderAndLayout";
import Dialog from "../DialogBox/DialogBox";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvBuilder } from "filefy";
import { Chip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  button: {
    textAlign: "center",
    backgroundColor: "#4094F7",
    color: "#FFFFFF",
    borderRadius: "0.5rem",
    marginLeft: "29.8rem",
    marginBottom: "1rem",
  },
});

export default function Projects() {
  const classes = useStyles();
  const [product, setProduct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [callingBackend, setCallingBackend] = useState(false);
  const [succesMessage, setSuccesMessage] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [productMessage, setProductMessage] = useState(false);
  const [item, setItem] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(10);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const notify = () =>
    succesMessage
      ? toast.success("Action successful!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : failMessage
      ? toast.error("Action failed!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : productMessage
      ? toast.warn("Product already exists!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : null;

  const handleClickOpen = (row) => {
    setItem(row);
    setOpen(true);
    setInitialValues(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      setCallingBackend(true);
      const { data } = await axios.get(
        `/project?page_number=${pageNumber}`,
        config
      );
      setProduct(data);
      setFilteredItems(data);
      setCallingBackend(false);
    } catch (error) {
      setFailMessage(true);
    }
  };

  const getTotalPageCount = async () => {
    setCallingBackend(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const { data } = await axios.get(`/project/pageCount`, config);
    setCount(data);
    setCallingBackend(false);
  };

  useEffect(() => {
    loadData();
  }, [pageNumber]);

  useEffect(() => {
    getTotalPageCount();
  }, []);

  const deleteData = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const result = await axios.delete(`/project/admin/${row._id}`, config);
      if (result.status === 200) {
        setSuccesMessage(true);
        loadData();
      }
    } catch (error) {
      setFailMessage(true);
    }
  };

  const activeData = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      let payload = {
        status: "published",
      };
      const result = await axios.put(
        `/project/admin/${row._id}`,
        payload,
        config
      );
      if (result.status === 200) {
        setSuccesMessage(true);
        loadData();
      }
    } catch (error) {
      setFailMessage(true);
    }
  };

  useEffect(() => {
    if (succesMessage) {
      notify();
      setSuccesMessage(false);
    } else {
      if (failMessage) {
        notify();
        setFailMessage(false);
      } else {
        if (productMessage) {
          notify();
          setProductMessage(false);
        }
      }
    }
  }, [succesMessage, failMessage, productMessage]);

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    if (text) {
      const result = product.filter((item) => {
        const str = JSON.stringify(item.name).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      setFilteredItems(result);
    } else {
      setFilteredItems(product);
    }
  };

  useEffect(() => {
    if (filteredItems) {
      let data = [];
      for (let i = 0; i < filteredItems.length; i++) {
        let arrays = [
          `${filteredItems[i].name}`,
          filteredItems[i].industry,
          filteredItems[i].iPSensitiveDetails.map((item) => item.url),
          `${filteredItems[i].funding}`,
          `${filteredItems[i].unassignedUnits}`,
          `${filteredItems[i].status}`,
        ];
        data.push(arrays);
      }
      if (data.length === filteredItems.length) {
        setExportData(data);
      }
    }
  }, [filteredItems]);

  const csvBuilder = () => {
    new CsvBuilder("Projects.csv")
      .setColumns([
        "Name",
        "Industry",
        "IP sensitive Details",
        "Funding",
        "Unassigned Units",
        "Status",
      ])
      .addRows(exportData)
      .exportFile();
  };

  return (
    <Grid style={{ marginLeft: "11rem", marginTop: "1.5rem" }}>
      <HeaderAndLayout activeItem={"projects"} />
      <Grid item xs={6} md={6}>
        <h1 style={{ marginBottom: "1rem" }}> Projects</h1>
      </Grid>
      <form className="search">
        <div
          className="input-group"
          style={{ flexBasis: "100%", paddingBottom: "1rem" }}
        >
          <input
            id="clearme"
            name="search"
            type="text"
            className="form-control"
            placeholder="Filter by Project Name.."
            onChange={onSearch}
            required
            style={{ fontSize: "1rem", padding: "0.3rem" }}
          />
          <label className="visually-hidden" htmlFor="search"></label>
          <Button
            onClick={csvBuilder}
            style={{
              marginLeft: "1rem",
              backgroundColor: "#009E60",
              color: "#ffff",
            }}
          >
            Export to CSV
          </Button>
        </div>
      </form>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>
                  IP sensitive Details/
                  <br />
                  Document links
                </TableCell>
                <TableCell>Funding</TableCell>
                <TableCell>
                  Unassigned
                  <br />
                  Units
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {callingBackend ? (
              <BeatLoader />
            ) : (
              <TableBody>
                {filteredItems.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.industry}</TableCell>
                    <TableCell>
                      {row.iPSensitiveDetails?.map((item) => (
                        <div
                          key={item.public_id}
                          style={{
                            width: "250px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={item.url}
                          >
                            {item.url}
                          </a>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{row.funding}</TableCell>
                    <TableCell>{row.unassignedUnits}</TableCell>
                    <TableCell>
                      <Chip
                        color={
                          row.status == "pending" ? "secondary" : "primary"
                        }
                        label={row.status}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleClickOpen(row)}
                        variant="contained"
                        style={{ backgroundColor: "#0066B2", color: "#ffff" }}
                      >
                        Edit
                      </Button>
                      {row.status === "published" ? (
                        <Button
                          onClick={() => deleteData(row)}
                          variant="contained"
                          style={{
                            backgroundColor: "#ff0000",
                            color: "white",
                            marginLeft: "0.5rem",
                            width: "6rem",
                          }}
                        >
                          Unpublish
                        </Button>
                      ) : (
                        <Button
                          onClick={() => activeData(row)}
                          variant="contained"
                          style={{
                            backgroundColor: "#009E60",
                            color: "white",
                            marginLeft: "0.5rem",
                            width: "6rem",
                          }}
                        >
                          Publish
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(count / 10)}
          page={pageNumber}
          onChange={handlePageChange}
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Grid>

      <Dialog
        handleClickOpen={handleClickOpen}
        open={open}
        handleClose={handleClose}
        loadData={loadData}
        initialValues={initialValues}
        item={item}
        setSuccesMessage={setSuccesMessage}
        setFailMessage={setFailMessage}
        setProductMessage={setProductMessage}
        type="proj"
      />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHove
      />
    </Grid>
  );
}
