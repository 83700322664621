import * as React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from "react";
import axios from "axios";
import { TextField, Select, MenuItem, FormControl, InputLabel, Input } from '@material-ui/core';
import './resources.css';

export default function FormDialog({ open, handleClose, data, onChange, handleImageChange, handleFormSubmit, axiosConfig, rowUpdate }) {
  const [resourceTypes, setResourceTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchData = async () => {
    // Get all resource types
    const _resourceTypes = await axios.get(`/resource-type`, axiosConfig);
    setResourceTypes(_resourceTypes.data);
    // Get all categories
    const _categories = await axios.get(`/category`, axiosConfig);
    setCategories(_categories.data)
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="section">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="title" id="alert-dialog-title">{rowUpdate ? "Update Resource Type" : "Add Resource Type"}</DialogTitle>
        <DialogContent>
          <form>
            <TextField name="code" value={data.code} onChange={e => onChange(e)} placeholder="Enter Code" label="Code" variant="outlined" margin="dense" fullWidth />
            <TextField name="name" value={data.name} onChange={e => onChange(e)} placeholder="Enter Name" label="Name" variant="outlined" margin="dense" fullWidth />
            <TextField name="description" value={data.description} onChange={e => onChange(e)} placeholder="Enter Description" label="Description" variant="outlined" margin="dense" fullWidth />
            <TextField name="website" value={data.website} onChange={e => onChange(e)} placeholder="Enter Website" label="Website" variant="outlined" margin="dense" fullWidth />

            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel>Type</InputLabel>
              <Select name="type" label="Type" value={data.type?._id || data.type} onChange={e => onChange(e)}>
                {resourceTypes.map((type, index) => (
                  <MenuItem key={index} value={type._id}>
                    {type.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel>Category</InputLabel>
              <Select name="category" label="Category" value={data.category?._id || data.category} onChange={e => onChange(e)}>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={data.status}
                onChange={e => onChange(e)}
                label="Status"
                displayEmpty
                inputProps={{ 'aria-label': 'Status' }}
              >
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>

            <TextField
              name="ownerEmail"
              value={data.owner?.email}
              label="Owner Email"
              variant="outlined"
              margin="dense"
              disabled
              fullWidth
            />
            <FormControl variant="outlined" fullWidth margin="normal" required>
              <InputLabel id="owner-approval-label">Owner Approval</InputLabel>
              <Select
                labelId="owner-approval-label"
                name="ownerApproval"
                value={data.ownerApproval}
                onChange={e => onChange(e)}
                label="Owner Approval"
                displayEmpty
                inputProps={{ 'aria-label': 'Owner Approval' }}
              >
                <MenuItem value="" disabled>
                  Select Approval Status
                </MenuItem>
                <MenuItem value={true}>Approved</MenuItem>
                <MenuItem value={false}>Not Approved</MenuItem>
              </Select>

            </FormControl>


            <InputLabel htmlFor="image">Image</InputLabel>
            <FormControl variant="outlined" fullWidth margin="normal">
              <Input type="file" id="image" name="image" onChange={handleImageChange} />
              {data?.image?.url && (
                <img src={data.image.url} alt="Image" style={{ maxWidth: "100%", height: "auto" }} />
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
          <Button className="createbutton" onClick={(e) => handleFormSubmit(e)} variant="contained">
            {rowUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}