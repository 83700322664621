import * as React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { TextField, MenuItem } from '@material-ui/core';
import './categories.css';

export default function FormDialog({ open, handleClose, data, onChange, handleFormSubmit, rowUpdate }) {
  const { name, code, icon, status } = data;

  return (
    <div className="section">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="title" id="alert-dialog-title">{rowUpdate ? "Update Categories" : "Add Categories"}</DialogTitle>
        <DialogContent>
          <form>
            <TextField name="name" value={name} onChange={e => onChange(e)} placeholder="Enter Name" label="Name" variant="outlined" margin="dense" fullWidth />
            <TextField name="code" value={code} onChange={e => onChange(e)} placeholder="Enter Code" label="Code" variant="outlined" margin="dense" fullWidth />
            <TextField name="status" value={status} onChange={e => onChange(e)} placeholder="Enter Status" label="Status" variant="outlined" margin="dense" fullWidth select>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </TextField>
            <Typography variant="body1">
              Enter an Icon name exactly from the list of icons here:
              <Link href="https://mui.com/material-ui/material-icons" target="_blank" rel="noopener">
                https://mui.com/material-ui/material-icons
              </Link>
            </Typography>
            <TextField name="icon" value={icon} onChange={e => onChange(e)} placeholder="Enter Icon" label="Icon" variant="outlined" margin="dense" fullWidth />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">Cancel</Button>
          <Button className="createbutton" onClick={(e) => handleFormSubmit(e)} variant="contained">
            {rowUpdate ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
