import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HeaderAndLayout from "../layouts/HeaderAndLayout";
import Dialog from "../DialogBox/DialogBox";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvBuilder } from "filefy";
import { Chip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import NoImage from "../../assets/noimage.jpg";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  button: {
    textAlign: "center",
    backgroundColor: "#4094F7",
    color: "#FFFFFF",
    borderRadius: "0.5rem",
    marginLeft: "29.8rem",
    marginBottom: "1rem",
  },
});

export default function Organizations() {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [callingBackend, setCallingBackend] = useState(false);
  const [succesMessage, setSuccesMessage] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [organizationMessage, setOrganizationMessage] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(10);
  const [logo, setLogo] = useState(null);

  console.log("logo", logo);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const notify = () =>
    succesMessage
      ? toast.success("Action successful!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      : failMessage
        ? toast.error("Action failed!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        : organizationMessage
          ? toast.warn("Organization already exists!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          : null;

  const handleClickOpen = (row) => {
    setInitialValues(row);
    setOpen(true);
    setLogo(row.logo ? row.logo.url : null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    try {
      setCallingBackend(true);
      const { data } = await axios.get(
        `/organization?page_number=${pageNumber}`,
        config
      );
      setOrganizations(data);
      setFilteredItems(data);
      setCallingBackend(false);
    } catch (error) {
      setFailMessage(true);
    }
  };

  const getTotalPageCount = async () => {
    setCallingBackend(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const { data } = await axios.get(`/organization/pageCount`, config);
    setCount(data);
    setCallingBackend(false);
  };

  useEffect(() => {
    loadData();
  }, [pageNumber]);

  useEffect(() => {
    getTotalPageCount();
  }, []);

  const deleteData = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const result = await axios.delete(
        `/organization/admin/${row._id}`,
        config
      );
      if (result.status === 200) {
        setSuccesMessage(true);
        loadData();
      }
    } catch (error) {
      setFailMessage(true);
    }
  };

  const activeData = async (row) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      let payload = {
        status: "published",
      };
      const result = await axios.put(
        `/organization/admin/${row._id}`,
        payload,
        config
      );
      if (result.status === 200) {
        setSuccesMessage(true);
        loadData();
      }
    } catch (error) {
      setFailMessage(true);
    }
  };

  useEffect(() => {
    if (succesMessage) {
      notify();
      setSuccesMessage(false);
    } else {
      if (failMessage) {
        notify();
        setFailMessage(false);
      } else {
        if (organizationMessage) {
          notify();
          setOrganizationMessage(false);
        }
      }
    }
  }, [succesMessage, failMessage, organizationMessage]);

  const onSearch = (e) => {
    const text = String(e.target.value).toLowerCase();
    if (text) {
      const result = organizations.filter((item) => {
        const str = JSON.stringify(item.name).toLowerCase();

        if (str.search(text) >= 0) return item;
      });
      setFilteredItems(result);
    } else {
      setFilteredItems(organizations);
    }
  };

  useEffect(() => {
    if (filteredItems) {
      let data = [];
      for (let i = 0; i < filteredItems.length; i++) {
        let arrays = [
          `${filteredItems[i].name}`,
          filteredItems[i].industry,
          filteredItems[i].website,
          `${filteredItems[i].status}`,
        ];
        data.push(arrays);
      }
      if (data.length === filteredItems.length) {
        setExportData(data);
      }
    }
  }, [filteredItems]);

  const csvBuilder = () => {
    new CsvBuilder("Organizations.csv")
      .setColumns(["Name", "Industry", "Website", "Status"])
      .addRows(exportData)
      .exportFile();
  };

  return (
    <Grid style={{ marginLeft: "11rem", marginTop: "1.5rem" }}>
      <HeaderAndLayout activeItem={"organizations"} />
      <Grid item xs={6} md={6}>
        <h1 style={{ marginBottom: "1rem" }}>Organizations</h1>
      </Grid>
      <form className="search">
        <div
          className="input-group"
          style={{ flexBasis: "100%", paddingBottom: "1rem" }}
        >
          <input
            id="clearme"
            name="search"
            type="text"
            className="form-control"
            placeholder="Filter by Organization Name.."
            onChange={onSearch}
            required
            style={{ fontSize: "1rem", padding: "0.3rem" }}
          />
          <label className="visually-hidden" htmlFor="search"></label>
          <Button
            onClick={csvBuilder}
            style={{
              marginLeft: "1rem",
              backgroundColor: "#009E60",
              color: "#ffff",
            }}
          >
            Export to CSV
          </Button>
        </div>
      </form>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {callingBackend ? (
              <BeatLoader />
            ) : (
              <TableBody>
                {filteredItems.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      <img
                        src={row.logo ? row.logo.url : NoImage}
                        alt={row.name}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </TableCell>{" "}
                    {/* Display the logo */}
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.industry}</TableCell>
                    <TableCell>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={row.website}
                      >
                        {row.website}
                      </a>
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={
                          row.status === "pending" ? "secondary" : "primary"
                        }
                        label={row.status}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleClickOpen(row)}
                        variant="contained"
                        color="primary"
                      >
                        Edit
                      </Button>
                      {row.status === "published" ? (
                        <Button
                          onClick={() => deleteData(row)}
                          variant="contained"
                          style={{
                            backgroundColor: "#ff0000",
                            color: "white",
                            marginLeft: "0.5rem",
                            width: "6rem",
                          }}
                        >
                          Unpublish
                        </Button>
                      ) : (
                        <Button
                          onClick={() => activeData(row)}
                          variant="contained"
                          style={{
                            backgroundColor: "#009E60",
                            color: "white",
                            marginLeft: "0.5rem",
                            width: "6rem",
                          }}
                        >
                          Publish
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(count / 10)}
          page={pageNumber}
          onChange={handlePageChange}
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Grid>
      <Dialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        initialValues={initialValues}
        loadData={loadData}
        setSuccesMessage={setSuccesMessage}
        setFailMessage={setFailMessage}
        setProductMessage={setOrganizationMessage}
        type="org"
        logo={logo}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  );
}
