import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Grid } from '@material-ui/core';
import HeaderAndLayout from '../layouts/HeaderAndLayout';
import FormDialog from './dialog';
import './categories.css';
import axios from "axios";
import BeatLoader from 'react-spinners/BeatLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Categories() {
    const [open, setOpen] = useState(false);
    const [rowUpdate, setRowUpdate] = useState(false);
    const [formData, setFormData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [callingBackend, setCallingBackend] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [failMessage, setFailMessage] = useState(false);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const notify = () => successMessage ? toast.success('Action successful!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : failMessage ? toast.error('Action failed!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }) : null;

    const loadData = async () => {
        try {
            setCallingBackend(true);
            const { data } = await axios.get("/category/admin", axiosConfig);
            setTableData(data);
            setCallingBackend(false);
        } catch (error) {
            setFailMessage(true);
        }
    };

    useEffect(() => {
        loadData();
        if (successMessage || failMessage) {
            notify();
            setSuccessMessage(false);
            setFailMessage(false);
        }
    }, [successMessage, failMessage]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setRowUpdate(false);
        setFormData({});
        setOpen(false);
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        try {
            if (rowUpdate) {
                await axios.put(`/category/${formData._id}`, formData, axiosConfig);
            } else {
                await axios.post("/category", formData, axiosConfig);
            }
            setRowUpdate(false);
            setOpen(false);
            setFormData({});
            setSuccessMessage(true);
            loadData();
            e.preventDefault();
        } catch (error) {
            setFailMessage(true);
        }
    };

    // Move item up
    const moveItemUp = async (index) => {
        if (index > 0) {
            let newData = [...tableData];
            [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
            setTableData(newData);
            await updateOrder(newData);
        }
    };

    // Move item down
    const moveItemDown = async (index) => {
        if (index < tableData.length - 1) {
            let newData = [...tableData];
            [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
            setTableData(newData);
            await updateOrder(newData);
        }
    };

    // Update the order in the backend
    const updateOrder = async (newOrder) => {
        try {
            // Prepare the categories to send to the backend
            const categories = newOrder.map((category, index) => ({
                _id: category._id,
                position: index + 1 // Assign new position based on index
            }));

            // Send the updated positions to the backend
            const response = await axios.patch(`/category/update-positions`, categories, axiosConfig);
            console.log(response);
            setSuccessMessage(true);
        } catch (error) {
            console.error(error);
            setFailMessage(true);
        }
    };

    const columns = [
        { title: "Name", field: "name" },
        { title: "Code", field: "code" },
        { title: "Icon", field: "icon" },
        { title: "Status", field: "status" }
    ];

    return (
        <div className="container" style={{ marginTop: "2rem" }}>
            <HeaderAndLayout activeItem={"categories"} />
            <Grid align="right" >
                <Button
                    style={{ backgroundColor: "#009E60", color: "#ffff", marginBottom: "1rem" }}
                    variant="contained"
                    onClick={handleClickOpen}>+ Create Category</Button>
            </Grid>
            {callingBackend ? <BeatLoader /> :
                <MaterialTable columns={columns} data={tableData}
                    title="Categories"
                    options={{ search: false, filtering: false, paging: false, paginationType: "stepped", exportButton: false, exportAllData: false, actionsColumnIndex: -1, addRowPosition: "first" }}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit Category',
                            onClick: (event, rowData) => {
                                setFormData(rowData);
                                setRowUpdate(true);
                                setOpen(true);
                            }
                        },
                        {
                            icon: 'arrow_upward',
                            tooltip: 'Move Up',
                            onClick: (event, rowData) => {
                                const index = tableData.indexOf(rowData);
                                moveItemUp(index);
                            }
                        },
                        {
                            icon: 'arrow_downward',
                            tooltip: 'Move Down',
                            onClick: (event, rowData) => {
                                const index = tableData.indexOf(rowData);
                                moveItemDown(index);
                            }
                        }
                    ]}
                />}
            <FormDialog open={open} handleClose={handleClose} data={formData} onChange={onChange} handleFormSubmit={handleFormSubmit} rowUpdate={rowUpdate} />
            <ToastContainer position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div>
    );
}

export default Categories;
