import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Input } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import BeatLoader from "react-spinners/BeatLoader";
import DropFileInput from "../components/drop-file-input/DropFileInput";
import "../../styles/styles.css";
import DeleteIcon from "@material-ui/icons/Delete";

export default function ResponsiveDialog(props) {
  const [componentType, setComponentType] = useState("create");
  const [callingBackend, setCallingBackend] = useState(false);
  const { open, handleClose, loadData, logo } = props;
  const [finished, setFinished] = useState(false);
  const [finalValues, setFinalValues] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [check, setCheck] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const [initialValues, setInitialValues] = useState(() => {
    if (props.type === "org") {
      return {
        name: props.initialValues?.name || "",
        industry: props.initialValues?.industry || "",
        website: props.initialValues?.website || "",
      };
    } else {
      return {
        name: props.initialValues?.name || "",
        funding: props.initialValues?.funding || "",
        unassignedUnits: props.initialValues?.unassignedUnits || "",
        industry: props.initialValues?.industry || "",
        iPSensitiveDetails: props.initialValues?.iPSensitiveDetails || [],
      };
    }
  });

  const validationSchema =
    props.type === "org"
      ? Yup.object().shape({
          name: Yup.string()
            .required("Name is required!")
            .max(35, "Max length for the name is 35")
            .min(2, "Min length for the name is 2"),
          industry: Yup.string().required("Industry is required!"),
        })
      : Yup.object().shape({
          name: Yup.string()
            .required("Name is required!")
            .max(35, "Max length for the name is 35")
            .min(2, "Min length for the name is 2"),
          funding: Yup.string().required("Funding is required!"),
          unassignedUnits: Yup.number().required(
            "Unassigned Units is required!"
          ),
          industry: Yup.string().required("Industry is required!"),
        });

  useEffect(() => {
    if (props.initialValues) {
      setComponentType("update");
      setValues();
    }
  }, [props.initialValues]);

  const handleToggleUpload = () => {
    setShowUpload(!showUpload);
  };

  const handleCancelUpload = () => {
    setShowUpload(false);
    setSelectedLogo(null);
  };

  const setValues = () => {
    if (props.type === "org") {
      setInitialValues({
        name: props.initialValues?.name || "",
        industry: props.initialValues?.industry || "",
        website: props.initialValues?.website || "",
      });
    } else {
      setInitialValues({
        name: props.initialValues?.name || "",
        funding: props.initialValues?.funding || "",
        unassignedUnits: props.initialValues?.unassignedUnits || "",
        industry: props.initialValues?.industry || "",
        iPSensitiveDetails: props.initialValues?.iPSensitiveDetails || [],
      });
    }
  };

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const handleInput = (e) => {
    setInitialValues((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
  };

  const publishProduct = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    setCheck(true);
    let endpoint = "";

    if (props.type === "org") {
      endpoint = `/organization/admin/${props.initialValues._id}`;
      setInitialValues({
        name: "",
        industry: "",
        website: "",
      });
    } else {
      endpoint = `/project/admin/${props.initialValues._id}`;
      setInitialValues({
        name: "",
        funding: "",
        unassignedUnits: "",
        industry: "",
        iPSensitiveDetails: [],
      });
    }

    let payload = { ...finalValues };

    axios
      .put(endpoint, payload, config)
      .then((res) => {
        setFinalValues(null);
        setCallingBackend(false);
        setDocuments(null);
        handleClose();
        loadData();
        props.setSuccesMessage(true);
        if (res.status === 200) {
          setCheck(false);
        }
      })
      .catch((error) => {
        props.setFailMessage(true);
        setCheck(false);
      });
  };

  const submit = async () => {
    if (documents) {
      setFinalValues({
        ...initialValues,
        iPSensitiveDetails: [
          ...props.initialValues?.iPSensitiveDetails,
          ...documents,
        ],
      });
    } else if (selectedLogo) {
      setFinalValues({
        ...initialValues,
        logo: selectedLogo,
      });
    } else {
      setFinalValues({ ...initialValues });
    }
  };

  let newDocuments = [];
  const deleteDocuments = (indexDoc) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    newDocuments = props.initialValues?.iPSensitiveDetails.filter(
      (item, index) => index !== indexDoc
    );
    setFinalValues({ ...initialValues, iPSensitiveDetails: newDocuments });

    if (finalValues) {
      axios
        .put(`/project/admin/${props.initialValues._id}`, finalValues, config)
        .then((res) => {
          setInitialValues({
            name: "",
            funding: "",
            unassignedUnits: "",
            industry: "",
            iPSensitiveDetails: [],
          });
          setFinalValues(null);
          setCallingBackend(false);
          setDocuments(null);
          handleClose();
          loadData();
          props.setSuccesMessage(true);
        })
        .catch((error) => {
          props.setFailMessage(true);
        });
    }
  };

  useEffect(() => {
    if (finalValues) {
      setCallingBackend(false);
      setFinished(true);
    }
  }, [finalValues]);

  useEffect(() => {
    if (finished) publishProduct();
    setFinished(false);
  }, [finished]);

  const onFileChange = (files) => {
    setDocuments(files);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          errors,
          isValid,
          dirty,
          handleChange,
          values,
          setFieldValue,
          getFieldProps,
        }) => {
          return (
            <Form>
              <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {props.type === "org"
                    ? "Edit an Organization"
                    : "Edit a Project"}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Grid>
                        <Field
                          {...getFieldProps("name")}
                          onChange={handleInput}
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          multiline
                        ></Field>
                      </Grid>

                      <Grid style={{ marginTop: "1rem" }}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Industry
                          </InputLabel>
                          <Select
                            onChange={handleInput}
                            value={initialValues.industry || ""}
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Industry"
                            name="industry"
                          >
                            <MenuItem value="Consumer Discretionary">
                              Consumer Discretionary
                            </MenuItem>
                            <MenuItem value="Consumer Staples">
                              Consumer Staples
                            </MenuItem>
                            <MenuItem value="Construction">
                              Construction
                            </MenuItem>
                            <MenuItem value="Energy">Energy</MenuItem>
                            <MenuItem value="Materials">Materials</MenuItem>
                            <MenuItem value="Industrials">Industrials</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Financials">Financials</MenuItem>
                            <MenuItem value="Information Technology">
                              Information Technology
                            </MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Communication Services">
                              Communication Services
                            </MenuItem>
                            <MenuItem value="Utilities">Utilities</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {props.type === "org" && (
                        <Grid style={{ marginTop: "1rem" }}>
                          <Field
                            {...getFieldProps("website")}
                            onChange={handleInput}
                            name="website"
                            label="website"
                            component={TextField}
                            variant="outlined"
                            fullWidth
                            multiline
                          ></Field>
                        </Grid>
                      )}

                      {props.type === "proj" && (
                        <>
                          <Grid style={{ marginTop: "1rem" }}>
                            <Field
                              onChange={handleInput}
                              name="funding"
                              label="Funding"
                              component={TextField}
                              variant="outlined"
                              fullWidth
                            ></Field>
                          </Grid>
                          <Grid style={{ marginTop: "1rem" }}>
                            <Field
                              onChange={handleInput}
                              name="unassignedUnits"
                              label="Unassigned Units"
                              component={TextField}
                              variant="outlined"
                              fullWidth
                            ></Field>
                          </Grid>
                        </>
                      )}
                      {props.type === "proj" && (
                        <Grid style={{ marginTop: "2rem" }}>
                          <h5>Uploaded Documents</h5>
                          {props.initialValues?.iPSensitiveDetails?.map(
                            (item, index) => {
                              return (
                                <div key={index} style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      flex: 1,
                                      margin: "auto",
                                    }}
                                  >
                                    <a
                                      href={item.url}
                                      style={{ fontSize: "0.875rem" }}
                                    >
                                      {item.url}
                                    </a>
                                  </div>
                                  <div>
                                    <Button
                                      disabled={
                                        props.initialValues?.iPSensitiveDetails
                                          ?.length === 1
                                      }
                                      onClick={() => deleteDocuments(index)}
                                      color="secondary"
                                      variant="outlined"
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {props.type === "proj" && (
                      <Grid item md={6}>
                        <Grid
                          item
                          sx={{
                            border: "1px dashed ",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="box">
                            <h2 className="header">Documents Uploader</h2>
                            <DropFileInput
                              onFileChange={(files) => onFileChange(files)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )}

                    {props.type === "org" && (
                      <>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        >
                          <Input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleImageChange}
                          />
                          {(logo || selectedLogo) && (
                            <img
                              src={!selectedLogo ? logo : selectedLogo}
                              alt="Image"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          )}
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </DialogContent>
                {callingBackend ? (
                  <BeatLoader />
                ) : (
                  <DialogActions>
                    <Button
                      onClick={submit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValid || check}
                    >
                      Update
                    </Button>
                    <Button onClick={handleClose} autoFocus color="secondary">
                      Cancel
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
